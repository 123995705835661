
  import CategoriesMenuItems from './../navbar/CategoriesMenuItems';
  import BDropdownHover from './../BDropdownHover';

  export default {
    name: 'Megamenu2',
    components: {
      CategoriesMenuItems,
      BDropdownHover,
    },
    data() {
      return {
        activeItem: null,
        openMenuIndex: null,
        isMegaMenuContentId: null,
      };
    },
    props: {
      megamenu: {
        type: Object,
        default: () => ({}),
        required: false,
      },
    },
    mounted() {
      document.addEventListener('click', this.handleCloseMenu);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.handleCloseMenu);
    },
    methods: {
      getMenuItemClass(item, index) {
        const menuType = item?.openBy === 'click' ? 'megamenu-item-click' : 'megamenu-item';

        return [
          menuType,
          'text-nowrap',
          item.class || 'mx-auto px-3',
          { 'megamenu-show': this.openMenuIndex === index },
        ];
      },
      toggleMenu(index) {
        this.openMenuIndex = this.openMenuIndex === index ? null : index;
        this.activeItem = this.megamenu.items[index];
        this.isMegaMenuContentId = this.activeItem.html.includes('megamenu-show');
      },
      handleMegaMenuContent(event) {
        const element = event.target;

        if (this.isMegaMenuContentId) {
          let parent = element.parentElement;
          while (parent) {
            if (parent.id === 'megamenuWrapper') {
              this.closeMenu();
              parent = false;
            } else if (parent.classList.contains('megamenu-show')) {
              parent = false;
            }
            parent = parent.parentElement;
          }
        }

        if (event.target?.tagName === 'A') {
          this.closeMenu();
        }
      },
      handleCloseMenu(event) {
        if (!this.$el.contains(event.target)) {
          this.closeMenu();
        }
      },
      closeMenu() {
        if (this.openMenuIndex !== null) {
          this.openMenuIndex = null;
        }
      },
      validHtml(html) {
        try {
          const div = document.createElement('div');
          div.innerHTML = html;
          return div.innerHTML;
        } catch {
          return html;
        }
      },
    },
  };

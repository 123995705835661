
  export default {
    name: 'InfiniteScroll1',
    props: {
      images: Array,
      parentContainer: Object,
      container: Object,
      scrollSettings: Object,
    },
    data() {
      return {
        calcucatedImages: [],
        defaultValues: {
          gap: 20,
          speed: 25,
          logoWidth: 'auto',
        },
        logoBlockWidth: 0,
        containerBlockWidth: 0,
        actualLogoBlockWidth: 0,
        loadedIndex: 0,
      };
    },
    mounted() {
      this.calcucatedImages = [...this.images];
      this.watchLoadedImageIndex = this.$watch(
        'loadedIndex', () => {
          if (this.loadedIndex === this.images.length) {
            this.handleLogoBlocks();
          }
        }
      );
    },
    computed: {
      styleDataSet() {
        const gap = this.scrollSettings.gap ? this.scrollSettings.gap : this.defaultValues.gap;
        const speed = this.scrollSettings.speed ? this.scrollSettings.speed : this.defaultValues.speed;
        const logoWidth = this.scrollSettings.logoWidth ? `${this.scrollSettings.logoWidth}px` : this.defaultValues.logoWidth;
        const blockWidth = +this.actualLogoBlockWidth + +gap;
        return {
          '--scroll-gap': `${gap}px`,
          '--scroll-speed': `${speed}s`,
          '--scroll-logo-width': logoWidth,
          '--scroll-width-dataset0-start': '0',
          '--scroll-width-dataset0-end': `-${blockWidth}px`,
          '--scroll-width-dataset1-start': `${blockWidth}px`,
          '--scroll-width-dataset1-end': '0',
          '--scroll-width-dataset2-start': `${blockWidth * 2}px`,
          '--scroll-width-dataset2-end': `${blockWidth}px`,
        };
      },
    },
    methods: {
      onImgLoad() {
        this.loadedIndex++
      },
      handleLogoBlocks() {
        this.$nextTick(() => {
          this.logoBlockWidth = this.$refs?.testLogos.scrollWidth;
          console.log('this.logoBlockWidth', this.logoBlockWidth);
          this.containerBlockWidth = this.$refs?.container.scrollWidth;
          console.log('this.containerBlockWidth', this.containerBlockWidth);

          // get block index: if getBlocksIndex > 1 need add items to original array of images
          const getBlocksIndex = this.getBlocksIndex();

          if (getBlocksIndex > 1) {
            // add images to original array if it needed: 
            // if width of original logo block is shorter than window width
            this.addItemsToLogoBlock(getBlocksIndex);
          } 
          // recalculating width for images blocks
          this.getActualLogoBlockWidth();
          this.destroyWatch();
        });
      },
      getBlocksIndex() {
        return Math.round(this.containerBlockWidth / this.logoBlockWidth);
      },
      addItemsToLogoBlock(index) {
        if (index > 1) {
          for (let i = 1; i < index; i++) {
            this.calcucatedImages.push(...this.images)
          }
        }
      },
      getActualLogoBlockWidth() {
        this.$nextTick(() => {
          this.actualLogoBlockWidth = this.$refs.logos[0].scrollWidth
        })
      },
      destroyWatch() {
        this.watchLoadedImageIndex();
      },
    }
  };
